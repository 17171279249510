














import { Route } from 'vue-router'
import { mapGetters, mapActions, mapState } from 'vuex'
import PageErrorMessage from '~/components/car/dealers/site/error/PageErrorMessage.vue'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { DEALER_SITE_ERROR_NS } from '~/store/modules/shared/dealers/site/error/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteComponentService from '~/services/dealers/site/DealerSiteComponentService'
import { getNamespacedStore } from '~/utils/store'
import { defineComponent } from '@nuxtjs/composition-api'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    PageErrorMessage,
    'custom-page': defineVue3AsyncComponent(() =>
      import('~/pages/car/dealers/site/pages/custom-page.vue')
    ),
    search: defineVue3AsyncComponent(() =>
      import('~/pages/car/dealers/site/pages/search.vue')
    ),
    contact: defineVue3AsyncComponent(() =>
      import('~/pages/car/dealers/site/pages/contact.vue')
    ),
    classified: defineVue3AsyncComponent(() =>
      import('~/pages/car/dealers/site/classified.vue')
    )
  },
  async asyncData(ctx) {
    if (process.client) {
      // We must not load the page data on the client side
      // because it causes state mismatches
      // TODO Nuxt3: investigate a fix here later
      return
    }

    const dsStore = getNamespacedStore(ctx.store, DEALER_SITE_NS)
    await dsStore.dispatch('loadSite', ctx)

    const { route, redirect } = ctx

    await dsStore.dispatch(`loadPageByPath`, {
      to: route,
      redirect
    })
  },
  computed: {
    ...mapState(DEALER_SITE_NS, ['indexPageType']),
    ...mapGetters(DEALER_SITE_NS, ['activePage', 'shouldRenderIndexPage']),
    ...mapGetters(DEALER_SITE_ERROR_NS, ['errorExists']),
    ...mapDeps({ dealerSiteComponentService: DealerSiteComponentService })
  },
  watch: {
    async $route(to: Route, from: Route) {
      await this.loadPageByPath({ to, from })
    }
  },
  methods: {
    ...mapActions(DEALER_SITE_NS, ['loadPageByPath'])
  }
})
